@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
    @font-face {
        font-family: Sequel;
        font-weight: 900;
        src: url(./assets/fonts/SequelBlack/Sequel100Black-95.ttf) format("woff");
    }
    
    html{
        scroll-behavior: smooth;
    }
    body{
        margin: 0;
        padding: 0;
        background: #EEEEEE;
        box-sizing: border-box;
    }
    h1, h1>span{
        font-family: "Sequel";
        -webkit-text-stroke: .5px white;
    }
    *{
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
    }
}